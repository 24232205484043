<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :md="6" :sm="24">
            <a-form-item label="订单号">
              <a-input v-model="queryParam.order_no" placeholder="请输入订单号" @keyup.enter.native="$refs.table.refresh(true)" allow-clear/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0" allow-clear>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.wechat_order_status">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" icon="plus" @click="handleAdd">生成拣货单</a-button>
          </a-col>
          <a-col :md="2" :sm="24" v-if="false">
            <a-button type="primary" @click="orderAutoToPickConfig">自动生成拣货单配置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :row-selection="rowSelection"
      :scroll="{ x: 1300 }"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 100px; max-width: 140px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleDetail(record)">详情</a>
        </template>
      </span>
    </s-table>
    <create-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <order-circulate-config
      ref="configModal"
      v-if="configVisible"
      :visible="configVisible"
      :loading="confirmLoading"
      :title="title"
      :model="mdl"
      @cancel="handleConfigCancel"
      @ok="handleConfigOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import CreateForm from '@/views/c-wms/wechat_order/modules/CreateForm'
import OrderCirculateConfig from '@/views/a-applet/shop_manage/OrderCirculateConfig'
import { Base64 } from 'js-base64'
import {
  wechat_order_list,
  wms_wechat_order_pick_list,
  wmsOrderToWarehouseConfig,
  putWmsOrderToWarehouseConfig
} from '@/api/c_wms_wechat_counter_order'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    OrderCirculateConfig
  },
  data () {
    return {
      title: '自动生成拣货单',
      loading: false,
      configVisible: false,
      confirmLoading: false,
      // 创建窗口控制
      visible: false,
      select_status_visible: false,
      select_edit_record: {},
      select_status_data: '',
      selectedRowKeys: [],
      selectedRows: [],
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          ellipsis: true,
          width: 200,
          fixed: 'left',
          dataIndex: 'order_no'
        },
        {
          title: '商品总价',
          width: 150,
          align: 'right',
          dataIndex: 'goods_value',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '运杂费',
          width: 150,
          align: 'right',
          dataIndex: 'freight',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '非现金抵扣',
          width: 150,
          align: 'right',
          dataIndex: 'discount',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '实际支付',
          width: 120,
          align: 'right',
          dataIndex: 'actual_paid',
          scopedSlots: { customRender: 'money_render' }
        },
        {
          title: '商品类型',
          ellipsis: true,
          width: 150,
          align: 'center',
          dataIndex: 'goods_type',
          customRender: (text) => this.$Dictionaries.goods_type[text] || '无'
        },
        {
          title: '配送方式',
          width: 100,
          ellipsis: true,
          dataIndex: 'distribution',
          customRender: text => this.$Dictionaries.delivery_method[text] || '无'
        },
        {
          title: '交易ID',
          ellipsis: true,
          width: 150,
          dataIndex: 'transaction_id',
          customRender: text => text || '-'
        },
        {
          width: 170,
          title: '购买人姓名',
          align: 'center',
          dataIndex: 'buyer_username',
          ellipsis: true
        },
        {
          width: 150,
          title: '接收人姓名',
          dataIndex: 'receiver_name',
          ellipsis: true
        },
        {
          width: 150,
          title: '接收人手机号',
          dataIndex: 'receiver_phone',
          ellipsis: true
        },
        {
          width: 200,
          title: '地址',
          dataIndex: 'province',
          ellipsis: true,
          customRender: (text, record) => {
            return text + '' + record.city + '' + record.county + '' + record.address
          }
        },
        {
          width: 150,
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true
        },
        {
          width: 100,
          title: '状态',
          dataIndex: 'status',
          fixed: 'right',
          customRender: (text) => this.$Dictionaries.wechat_order_status[text] || '无'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          fixed: 'right',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return wechat_order_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  computed: {
    rowSelection () {
      const that = this
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          that.onSelectChange(selectedRowKeys, selectedRows)
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.status !== 1 && record.status !== 4
          }
        })
      }
    }
  },
  methods: {
    handleAdd () {
      if (this.selectedRows.length === 0) {
        this.$message.warning('选择订单')
        return
      }
      this.mdl = null
      this.visible = true
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleDetail (record) {
      this.$router.push({ path: '/zb/wms/wechat_order_detail', query: { record: Base64.encode(JSON.stringify(record)) } })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          values.order_nos = this.selectedRows.map(item => {
            return item.order_no
          })
          wms_wechat_order_pick_list(values).then(res => {
            this.visible = false
            this.confirmLoading = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.$refs.table.refresh()
            }
            if (res.fail_order !== undefined && res.fail_order.length > 0) {
              this.$error({
                title: '错误单号',
                content: res.fail_order.join(', ')
              })
            }
          }).catch((err) => {
            console.log(err)
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    orderAutoToPickConfig () {
      wmsOrderToWarehouseConfig({ type: 1 }).then(res => {
        console.log(res)
        if (res.code === 1000 && res.data.entries.length > 0) {
          this.mdl = res.data.entries[0]
          this.configVisible = true
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    handleConfigCancel () {
      this.configVisible = false
      this.confirmLoading = false
    },
    handleConfigOk () {
      const form = this.$refs.configModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          putWmsOrderToWarehouseConfig(values, this.mdl.id).then(res => {
            if (res.code === 1000) {
              this.handleConfigCancel()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>
